































import Vue from 'vue';
import IItemState,{ deconstruct } from '@/models/items/IItemState';
import ITypedMap from '@/models/util/ITypedMap';
import { ItemState, IItemStateInfo, StateInfo } from '@/models/items/ItemEnums';

interface IData {

}

interface IItemStateInfoPair {
    state: ItemState;
    info: IItemStateInfo;
}

interface IRangedStatInfo {
    low: number;
    high: number;
}

export default Vue.extend({
    props: {
        statSet: {
            type: Array as () => IItemState[],
            required: true,
        },
        enhanceStatSet: {
            type: Array as () => IItemState[],
        },
        hideHeader: {
            type: Boolean as () => boolean,
        }
    },
    data(): IData {
        return {
        };
    },
    computed: {
        deconStatSet(): IItemState[] {
            return deconstruct(this.statSet);
        },
        deconEnhanceStatSet(): IItemState[] {
            if (this.enhanceStatSet) {
                return deconstruct(this.enhanceStatSet);
            }
            return [];
        },
        hasEnhance(): boolean {
            return this.enhanceStatSet && this.enhanceStatSet.length > 0;
        },
        listOfStates(): IItemStateInfoPair[] {
            let stats = this.deconStatSet.map((v) => v.state);
            if (this.hasEnhance) {
                stats = stats.concat(this.deconEnhanceStatSet.map((v) => v.state));
            }

            stats = stats.filter((v, i, a) => a.indexOf(v) === i);

            return stats.map((s) => ({
                state: s,
                info: StateInfo[s],
            })).map((v) => {
                if (!v.info) {
                    v.info = {
                        abbv: `unk${v.state}`,
                        type: 'stat',
                    };
                }
                
                return v;
            });
        },
        listOfStatesWithoutDmg(): IItemStateInfoPair[] {
            return this.listOfStates.filter((v) => {
                switch (v.state) {
                    case ItemState.PHYSICAL_DAMAGE_MIN:
                    case ItemState.PHYSICAL_DAMAGE_MAX:
                    case ItemState.MAGICAL_DAMAGE_MIN:
                    case ItemState.MAGICAL_DAMAGE_MAX:
                    case ItemState.PHYSICAL_DAMAGE_MIN_PERCENT:
                    case ItemState.PHYSICAL_DAMAGE_MAX_PERCENT:
                    case ItemState.MAGICAL_DAMAGE_MIN_PERCENT:
                    case ItemState.MAGICAL_DAMAGE_MAX_PERCENT:
                        return false;
                }

                return true;
            });
        },
        listOfRangedStates(): IItemStateInfoPair[] {
            return this.listOfStates.map((v) => {
                switch (v.state) {
                    case ItemState.PHYSICAL_DAMAGE_MIN:
                    case ItemState.PHYSICAL_DAMAGE_MAX:
                        return ItemState.PHYSICAL_DAMAGE_MINMAX;

                    case ItemState.MAGICAL_DAMAGE_MIN:
                    case ItemState.MAGICAL_DAMAGE_MAX:
                        return ItemState.MAGICAL_DAMAGE_MINMAX;

                    case ItemState.PHYSICAL_DAMAGE_MIN_PERCENT:
                    case ItemState.PHYSICAL_DAMAGE_MAX_PERCENT:
                        return ItemState.PHYSICAL_DAMAGE_MINMAX_PERCENT;

                    case ItemState.MAGICAL_DAMAGE_MIN_PERCENT:
                    case ItemState.MAGICAL_DAMAGE_MAX_PERCENT:
                        return ItemState.MAGICAL_DAMAGE_MINMAX_PERCENT;
                }

                return null;
            }).filter((v) => v != null)
            .filter((v, i, a) => a.indexOf(v) === i)
            .reverse()
            .map((v) => ({
                state: v!,
                info: StateInfo[v!],
            }));
        }
    },
    methods: {
        renderStat(stateInfo: IItemStateInfoPair, type: 'b'|'e'|'t' = 'b'): string {
            const info = stateInfo.info;
            if (info.type === 'compound') {
                const compound = info.compound!;
                let parts: string[] = [];
                for (let c of compound) {
                    const state = this.getState(c, type);
                    let value = '';
                    if (state) {
                        if (info.subtype === 'stat') {
                            value = state.value.toLocaleString(undefined, {
                                useGrouping: true,
                                maximumFractionDigits: 0,
                            });
                        } else if (info.subtype === 'percent') {
                            value = (state.value * 100).toLocaleString(undefined, {
                                useGrouping: true,
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            }) + '%';
                        } else {
                            value = state.value.toString();
                        }

                        parts.push(value);
                    }
                }

                // If the min and max values are the same, just merge them
                parts = parts.filter((v, i, a) => a.indexOf(v) === i);

                return parts.join(' - ');
            } else {
                const state = this.getState(stateInfo.state, type);
                if (state) {
                    if (info.type === 'stat') {
                        return state.value.toLocaleString(undefined, {
                            useGrouping: true,
                            maximumFractionDigits: 0,
                        });
                    } else if (info.type === 'percent') {
                        return (state.value * 100).toLocaleString(undefined, {
                            useGrouping: true,
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        }) + '%';
                    }

                    return state.value.toString();
                } else {
                    return '—';
                }
            }
        },
        getState(state: ItemState, type: 'b'|'e'|'t' = 'b'): IItemState|null {
            if (type === 'b') {
                return this.deconStatSet.find((v) => v.state === state) || null;
            }
            if (type === 'e' && this.hasEnhance) {
                return this.deconEnhanceStatSet.find((v) => v.state === state) || null;
            }
            if (type === 't') {
                // todo
                return null;
            }

            return null;
        },
    }
});
